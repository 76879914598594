<template>
  <div class="grid  gap-y-3 px-0 md:px-5 py-3">
      <v-loading :is_loading="isloading" />
      <div class="block">
          <div>
            <label class="font-sans text-base text-gray-500 font-bold tracking-wide p-0 m-0">Detalle del Servicio</label>
          </div>
          <div>
            <label class="font-sans text-sm text-gray-400 font-thin tracking-wide p-0 m-0">Agrega todo lo que incluye tu servicio</label>
          </div>
      </div>
      
      <div class="w-full md:w-1/2 grid gap-y-3" >
          <button  v-if="!iscreate && !isupdate" @click="iscreate = true"  class="flex items-center gap-2 px-5 py-2 border-2 border-dashed border-fucsia-lighter text-fucsia-lighter font-sans font-medium justify-center">
              <f-icon :icon="['fa', 'plus']" />
              <span>AGREGAR ELEMENTO</span>
          </button>
          <div v-if="iscreate" class="border border-gray-200 rounded-md px-1 py-1 grid gap-y-2"  >
               <div class="grid grid-cols-1 px-1 py-1 gap-5">
                <div>
                        <label class="text-base font-sans font-semibold">Nombre</label>
                        <input :disabled="isloading" v-model="$v.servicedetail.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                        <small class="text-error" v-show="$v.servicedetail.name.$anyDirty && !$v.servicedetail.name.required">* El nombre es requerido</small>
                </div>
            </div>
             <div class="grid grid-cols-1 px-1 py-1 gap-5">
                <div>
                    <label class="text-base font-sans font-semibold">Descripción</label>
                    <textarea :disabled="isloading" v-model="$v.servicedetail.description.$model" class="input-full-fucsia" rows="3" placeholder="Ingrese una descripción detallada"></textarea>
                    <div class="flex justify-between">
                        <div>         
                            <small class="text-error" v-show="$v.servicedetail.description.$anyDirty && !$v.servicedetail.description.required">* La descripción es requerida</small>
                            <small class="text-error" v-show="$v.servicedetail.description.$anyDirty && !$v.servicedetail.description.minLength">* La descripción debe contener un mínimo de {{$v.servicedetail.description.$params.minLength.min}} caracteres</small>
                            <small class="text-error" v-show="$v.servicedetail.description.$anyDirty && !$v.servicedetail.description.maxLength">* La descripción debe contener un máximo de {{$v.servicedetail.description.$params.maxLength.max}} caracteres</small>
                        </div>
                        <div>
                            <small>{{ servicedetail.description.length +' / '+$v.servicedetail.description.$params.maxLength.max }}</small>
                        </div>
                    </div>
                </div>
           </div>
           <div class="grid md:flex justify-between grid-cols-1 px-3 py-1 gap-5">
               <div>
                <label class="text-sm font-sans font-semibold">Estatus</label>
                <div class="w-min" >
                        <v-switch
                        color="success"
                        v-model="$v.servicedetail.active.$model"
                        hide-details
                        :label="`${servicedetail.active ? 'Activo' : 'Inactivo'}`"
                        style="margin-top: 0px"
                        ></v-switch>
                </div>
               </div>
               <div class="flex items-center gap-5">
                <div class="grid">
                    <label class="text-sm font-sans font-semibold">Fecha de Expiración</label>
                    <div class="flex gap-1 items-center">
                        <masked-input v-model="$v.servicedetail.end_date.$model" :mask="'####-##-##'" type="text" class="input-full-ligth-sm" placeholder="Ingrese la fecha"/>
                       
                         <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="servicedetail.end_date"
                            transition="scale-transition"
                            persistent
                            width="290px"
                         >
                        <template v-slot:activator="{ on, attrs }">
                                <f-icon v-bind="attrs" v-on="on" class="text-2xl cursor-pointer text-gray-500" :icon="['fa', 'clock']" />
                        </template>
                        <v-date-picker
                        v-model="servicedetail.end_date"
                        no-title
                        scrollable
                        landscape
                        header-color="blue darken-4"
                        >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false" > Cancel </v-btn>
                        <v-btn text color="primary"  @click="$refs.menu.save(servicedetail.end_date)" > OK </v-btn>
                        </v-date-picker>
                    </v-menu>
                  
                    </div>
                </div>
                <div>
                     <label class="text-sm font-sans font-semibold">¿Cerrar al finalizar?</label>
                    <div class="w-min" >
                            <v-switch
                            color="success"
                            v-model="$v.servicedetail.close_end.$model"
                            hide-details
                            :label="`${servicedetail.close_end ? 'Desactivar' : 'Mantener'}`"
                              style="margin-top: 0px"
                            ></v-switch>
                    </div>
                </div>
               </div>
           </div>
           <div class="flex gap-5 items-center justify-end w-full">
               <div >
                    <button @click="iscreate = false" class="text-gray-400 text-xl hover:text-gray-500">
                            <f-icon :icon="['fa', 'times']" />
                    </button>
               </div>
               <div>
                  <button :disabled="isloading"
                    class="flex items-center justify-center gap-2 w-full bg-fucsia-lighter text-white text-sm font-semibold tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                @click="save" >
                 <f-icon v-if="isloading" class="animate-spin" :icon="['fa', 'spinner']" />
                   <span>Guardar</span> 
                </button>
               </div>
           </div>

          </div>
           <div v-if="isupdate" class="border border-gray-200 rounded-md px-1 py-1"  >
               <div class="grid grid-cols-1 px-1 py-1 gap-5">
                <div>
                        <label class="text-base font-sans font-semibold">Nombre</label>
                        <input :disabled="isloading" v-model="$v.servicedetail_selected.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                        <small class="text-error" v-show="$v.servicedetail_selected.name.$anyDirty && !$v.servicedetail_selected.name.required">* El nombre es requerido</small>
                </div>
            </div>
             <div class="grid grid-cols-1 px-1 py-1 gap-5">
                <div>
                    <label class="text-base font-sans font-semibold">Descripción</label>
                    <textarea :disabled="isloading" v-model="$v.servicedetail_selected.description.$model" class="input-full-fucsia" rows="3" placeholder="Ingrese una descripción detallada"></textarea>
                    <div class="flex justify-between">
                        <div>         
                            <small class="text-error" v-show="$v.servicedetail_selected.description.$anyDirty && !$v.servicedetail_selected.description.required">* La descripción es requerida</small>
                            <small class="text-error" v-show="$v.servicedetail_selected.description.$anyDirty && !$v.servicedetail_selected.description.minLength">* La descripción debe contener un mínimo de {{$v.servicedetail_selected.description.$params.minLength.min}} caracteres</small>
                            <small class="text-error" v-show="$v.servicedetail_selected.description.$anyDirty && !$v.servicedetail_selected.description.maxLength">* La descripción debe contener un máximo de {{$v.servicedetail_selected.description.$params.maxLength.max}} caracteres</small>
                        </div>
                        <div>
                            <small>{{ servicedetail_selected.description.length +' / '+$v.servicedetail_selected.description.$params.maxLength.max }}</small>
                        </div>
                    </div>
                </div>
           </div>
           <div class="grid md:flex justify-between grid-cols-1 px-3 py-1 gap-5">
               <div>
                <label class="text-sm font-sans font-semibold">Estatus</label>
                <div class="w-min" >
                        <v-switch
                        color="success"
                        v-model="$v.servicedetail_selected.active.$model"
                        hide-details
                        :label="`${servicedetail_selected.active ? 'Activo' : 'Inactivo'}`"
                        style="margin-top: 0px"
                        ></v-switch>
                </div>
               </div>
               <div class="flex items-center gap-5">
                <div class="grid">
                    <label class="text-sm font-sans font-semibold">Fecha de Expiración</label>
                    <div class="flex gap-1 items-center">
                        <masked-input v-model="$v.servicedetail_selected.end_date.$model" :mask="'####-##-##'" type="text" class="input-full-ligth-sm" placeholder="Ingrese la fecha"/>
                        <r-date-picker 
                            :has_close="true" 
                            :is_close_end="servicedetail_selected.is_close_end" 
                            :prevdate="servicedetail_selected.end_date" 
                            v-on:date="event => onChangeDateUpdate(event)"
                            v-on:remove="onRemoveDateUpdate"
                             >
                               <f-icon  class="text-2xl cursor-pointer text-gray-500" :icon="['fa', 'clock']" />
                          </r-date-picker>
                    </div>
                </div>
                <div>
                     <label class="text-sm font-sans font-semibold">¿Cerrar al finalizar?</label>
                    <div class="w-min" >
                            <v-switch
                            color="success"
                            v-model="$v.servicedetail_selected.is_close_end.$model"
                            hide-details
                            :label="`${servicedetail_selected.is_close_end ? 'Desactivar' : 'Mantener'}`"
                              style="margin-top: 0px"
                            ></v-switch>
                    </div>
                </div>
               </div>
           </div>
           <div class="grid md:flex gap-2 justify-self-center md:justify-end">
               <div >
                    <button :disabled="isloading"
                        class="w-full bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                    @click="isupdate = false"  >Cancelar</button>
               </div>
               <div>
                  <button :disabled="isloading"
                    class="flex items-center gap-2 w-full bg-fucsia-lighter text-white text-lg tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                @click="update(servicedetail_selected.id)" >
                 <f-icon v-if="isloading" class="animate-spin" :icon="['fa', 'spinner']" />
                    Guardar
                </button>
               </div>
           </div>
          </div>

          <div class="grid gap-y-2">
              <div  v-for="(detail, i) in service_details" :key="i" class="bg-gray-100 rounded sm:px-1 md:px-5 flex items-center justify-between gap-5 shadow-sm hover:shadow-md transition duration-200 ease-in-out ">
                  <div class="flex items-center gap-5">
                   <f-icon class="text-gray-400 hidden md:block" :icon="['fa', 'bars']" />
                    <v-checkbox
                        color="success"
                        v-model="detail.active"
                        @click="onStatus(detail)"
                    ></v-checkbox>
                    <div class="grid font-sans tracking-wide  cursor-pointer"  @click="onEdit(detail.id)">
                        <label  :class="{'text-gray-600': detail.active, 'text-gray-400': !detail.active}" class="font-semibold cursor-pointer">{{detail.name ? detail.name : ''}}</label>
                        <small class="text-gray-400">{{detail.description ? detail.description : ''}}</small>
                    </div>
                  </div>
                 <div class="relative">
                     <div class="flex items-center gap-2">
                          <r-date-picker 
                            :has_close="true" 
                            :is_close_end="detail.is_close_end" 
                            :prevdate="detail.end_date" 
                            v-on:date="event => onChangeDate(event, detail.id)"
                            v-on:remove="onRemoveDate(detail.id)"
                             >
                                <f-icon  :class="{'text-gray-500': detail.active, 'text-gray-400': !detail.active}" class="text-xl cursor-pointer" :icon="['fa', 'history']" />
                          </r-date-picker>
                        <f-icon @click="onDelete(detail.id)"  :class="{'text-gray-500': detail.active, 'text-gray-400': !detail.active}"  class="text-xl cursor-pointer" :icon="['fa', 'trash-alt']" />
                     </div>
                      <small v-if="detail.end_date" class="absolute -top-4 right-0 md:-right-4 bg-red-500 text-white rounded-sm rounded-full w-2 h-2" ></small>
                 </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import globalMixin from "@/mixins/globalMixin";
import ServiceDetail from '../../../../../models/service/servicedetail.model';
import { validationMixin } from 'vuelidate';
import {  minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';
import serviceDetailService from '../../../../../services/service/servicedetail.service';

export default {
    mixins: [validationMixin, toastMixin, globalMixin],
    props: {
        id_service: {
            type: String,
            default: ""
        }
    },
    data: function () {
        return {
            isloading: false,
            iscreate: false,
            isupdate: false,
            menu: false,
            all: 1,
            servicedetail: new ServiceDetail(),
            servicedetail_selected: new ServiceDetail(),
            service_details: []
        }
    },
    validations: {
         servicedetail: {
            name: {
                 required: requiredIf(function() {
                     return !this.isupdate ? true : false;
                 })
            },
            description: {
                required: false,
                minLength: minLength(10),
                maxLength: maxLength(200)
            },
            id_service: {
                  required: requiredIf(function() {
                     return !this.isupdate ? true : false;
                 })
            },
            end_date: {
                required: false
            },
            active: {
                required: false
            },
            close_end: {
                required: false
            }
         },
         servicedetail_selected: {
            name: {
                 required:requiredIf(function() {
                     return this.isupdate ? true : false;
                 })
            },
            description: {
                required: false,
                minLength: minLength(10),
                maxLength: maxLength(200)
            },
            id_service: {
                 required:requiredIf(function() {
                     return this.isupdate ? true : false;
                 })
            },
            end_date: {
                required: false
            },
            active: {
                required: false
            },
            is_close_end: {
                required: false
            }
         }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            await this.getServiceDetails(this.id_service, this.all);
        },
        getServiceDetails: async function (id, all) {
            let response = await serviceDetailService.getByIdService(id, all);
            if(response.success) {
                this.service_details = response.data;
            }else {
                this.failure(response.message);
            }
        },
        
        save: async function () {
            this.servicedetail.id_service = this.id_service;

            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await serviceDetailService.save(this.servicedetail);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.iscreate = false;
                    this.init();
                }else {
                    this.failure(response.message);
                    this.iscreate = false;
                }
            }
        },
        update: async function(id) {
            this.servicedetail_selected.is_update_date = this.servicedetail_selected.is_update_date ? this.servicedetail_selected.is_update_date : true;
            this.servicedetail_selected.is_date_change = this.servicedetail_selected.is_date_change ? this.servicedetail_selected.is_date_change : false;
            this.servicedetail_selected.close_end = this.servicedetail_selected.is_close_end ? this.servicedetail_selected.is_close_end : false;

            this.$v.$touch();
            if(!this.$v.$anyError) {
               
                    this.isloading = true;
                    let response = await serviceDetailService.update(id, this.servicedetail_selected);
                    this.isloading = false;
                    if(response.success) {
                        this.success(response.message);
                        this.servicedetail_selected = null;
                        this.isupdate = false;
                        this.init();
                    }else {
                        this.failure(response.message);
                    }
            }
        },
        onStatus: async function (value) {
            const confirm = await this.confirm(`${value.active ? 'Mostrar' : 'Ocultar'}`, `¿Deseas ${value.active ? 'mostrar' : 'ocultar'} el elemento seleccionado?`);
            if(confirm) {
                const data = {
                    active: value.active,
                    type: 1
                };
                this.isloading = true;
                let response = await serviceDetailService.status(value.id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                    this.failure(response.message);
                    value.active = !value.active;
                }
                
            }else {
                value.active = !value.active;
            }
        },
        onDelete: async function(id) {
            const confirm = await this.confirm(`Eliminar Detalle`, `¿Deseas eliminar el detalle seleccionado?`);
            if(confirm) {
                  const data = {
                    active: false,
                    type: 2
                };
                this.isloading = true;
                let response = await serviceDetailService.status(id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                    this.failure(response.message);
                }

            }
        },
        onChangeDate: async function(date, id) {
            
            const confirm = await this.confirm(`Actualizar`, `¿Los datos son correctos?`);
            if(confirm) {
                const item_selected = this.service_details.find(x => x.id == id);

                if(item_selected) {
                    item_selected.end_date = date.date;
                    item_selected.close_end = date.close_end;
                    item_selected.is_update_date = true;
                    item_selected.is_date_change = date.is_update_date;
                    this.isloading = true;
                    let response = await serviceDetailService.update(id, item_selected);
                    this.isloading = false;
                    if(response.success) {
                        this.success(response.message);
                        this.init();
                    }else {
                        this.failure(response.message);
                    }
                }
            }
        },
        onRemoveDate: async function(id) {
             const confirm = await this.confirm(`Eliminar`, `¿Desea eliminar la fecha seleccionada?`);
            if(confirm) {
                const item_selected = this.service_details.find(x => x.id == id);
                if(item_selected) {
                    item_selected.end_date = '';
                    item_selected.close_end = false;
                    item_selected.is_update_date = true;
                    item_selected.is_date_change = true;
                    this.isloading = true;
                    let response = await serviceDetailService.update(id, item_selected);
                    this.isloading = false;
                    if(response.success) {
                        this.success(response.message);
                        this.init();
                    }else {
                        this.failure(response.message);
                    }
                }
            }
        },
        onRemoveDateUpdate: function() {
            this.servicedetail_selected.end_date = '';
            this.servicedetail_selected.close_end = false;
            this.servicedetail_selected.is_update_date = true;
            this.servicedetail_selected.is_date_change = true;
        },
        onChangeDateUpdate: function(date) {
                this.servicedetail_selected.is_close_end = date.close_end;
                this.servicedetail_selected.is_update_date = true;
                this.servicedetail_selected.is_date_change = date.is_update_date;
                if(date.is_update_date) {
                    this.servicedetail_selected.end_date = date.date; 
                   
                }

        },
        onEdit: async function(id) {
            this.isloading = true;
            let response = await serviceDetailService.getById(id);
            this.isloading = false;
            if(response.success) {
                this.isupdate = true;
                this.servicedetail_selected = response.data;
            }else {
                this.failure(response.message);
            }
        }
    },
    watch: {
        iscreate: function() {
            if(this.iscreate) {
                this.servicedetail = new ServiceDetail();
                this.$v.$reset();
            }
        }
    }
}
</script>

