import ApiProducts from "../config/ApiProducts";
import api_url from '../../utils/api.url';

export default  {


    /**
     * Función que permite guardar los datos del detalle del servicio
     * @param {*} data Modelo de datos del detalle del servicio
     * @returns 
     */
    async save(data) {
        try {
            const url = api_url.APP_PRODUCTS_SERVICE_DETAIL_SAVE;
            return await ApiProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funcion que permite obtener el detalle de los servicios por ID
     * @param {*} id_service ID del servicio
     * @param {*} all BIT para obtener todos o activos
     * @returns 
     */
    async getByIdService(id_service, all) {
        try {
            const url = api_url.APP_PRODUCTS_SERVICE_DETAIL_GET_BY_SERVICE + id_service + '/' + all;
            return await ApiProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los datos del detalle del servicio por ID
     * @param {*} id ID del detalle del servicio
     * @returns 
     */
    async getById(id) {
        try {
            const url = api_url.APP_PRODUCTS_SERVICE_DETAIL_BY_ID + id;
            return await ApiProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },
    

    /**
     * Funcionalidad que sirve para actualizar el estatus del detalle del servicio
     * @param {*} id ID del detalle
     * @param {*} data Modelo de datos del detalle
     * @returns 
     */
    async status(id, data) {
        try {
            const url = api_url.APP_PRODUCTS_SERVICE_DETAIL_STATUS + id;
            return await ApiProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funcion que permite actualizar los datos del detalle del servicio
     * @param {*} id ID del detalle de servicio
     * @param {*} data Modelo de datos a actualizar
     * @returns 
     */
    async update(id, data) {
        try {
            const url = api_url.APP_PRODUCTS_SERVICE_DETAIL_UPDATE + id;
            return await ApiProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },


}