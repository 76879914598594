<template>
  <div>
       <v-tabs
        color="blue darken-2"
        right
        v-model="tabmoreinfo"
        >
        <v-tab>MENSUAL</v-tab>
        <v-tab>HOY</v-tab>
        </v-tabs>
            <v-tabs-items v-model="tabmoreinfo">
                <v-tab-item>
                        <v-date-picker
                        v-model="picker"
                        flat
                        class="w-full"
                        locale="es"
                        color="pink lighten-1"
                        active-picker="DATE"
                        ></v-date-picker>
                </v-tab-item>
                    <v-tab-item>
                        <v-timeline
                        align-top
                        dense
                    >
                        <v-timeline-item
                        color="success"
                        small
                        v-for="(itemcita, x) in  [1, 2, 3]" :key="x"
                    >
                        <v-row class="pt-1">
                        <v-col>
                        <strong>5:30 pm</strong>
                        </v-col>
                        <v-col>
                        <strong>New Icon</strong>
                        <div class="text-caption">
                            Mobile App
                        </div>
                        </v-col>
                    </v-row>
                    </v-timeline-item>
                    </v-timeline>
                    </v-tab-item>
            </v-tabs-items>
  </div>
</template>

<script>
export default {
    data: function () {
        return {
             picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            tabmoreinfo: null,
        }
    }

}
</script>

<style>

</style>