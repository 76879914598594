export default class ServiceDetail {
    constructor(
        name,
        description,
        id_service,
        active,
        close_end,
        end_date,
    ) {
        this.name = name ? name : '',
        this.description = description ? description : '',
        this.id_service = id_service ? id_service : '',
        this.active = active ? active : false,
        this.close_end = close_end ? close_end : false,
        this.end_date = end_date ? end_date : ''
    }
}